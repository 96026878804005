import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  SubscriptionStatusEnum,
  SubscriptionStatusLabels,
} from "domain/subscription-plans/enums/subscription-status.enum";
import { BadgeComponent } from "components/badge/badge-component";
import {
  SubscriptionPlanFrequencyEnum,
  SubscriptionPlanFrequencyLabels,
} from "domain/subscription-plans/enums/subscription-plan-frequency.enum";
import { dateFormatter } from "helpers/date-formatter";
import { useNavigate } from "react-router-dom";
import { MINIMAL_DAYS_TO_SHOW_ALERT } from "helpers/constants";
import { actionCancelSubscription } from "actions/subscription-plan/action-cancel-subscription";
import { actionRefreshToken } from "actions/identity/action-refresh-token";
import { useState } from "react";

export interface Plan {
  title: string;
  subtitle: string;
  price: string;
  billingCycle: SubscriptionPlanFrequencyEnum;
  endsAt: string;
  status: SubscriptionStatusEnum;
}

interface CurrentPlanCardProps {
  plan: Plan;
}

export function CurrentPlanCard({ plan }: CurrentPlanCardProps) {
  const navigate = useNavigate();

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const isNewSubscription =
    Math.ceil(
      (new Date(plan.endsAt).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24)
    ) < MINIMAL_DAYS_TO_SHOW_ALERT ||
    [
      SubscriptionStatusEnum.EXPIRED,
      SubscriptionStatusEnum.INACTIVE,
      SubscriptionStatusEnum.TRIAL,
      SubscriptionStatusEnum.CANCELED,
    ].includes(plan.status);

  const getBgColor = (status: SubscriptionStatusEnum) => {
    switch (status) {
      case SubscriptionStatusEnum.ACTIVE:
        return "badge-bg-primary";
      case SubscriptionStatusEnum.TRIAL:
        return "badge-bg-warning";
      case SubscriptionStatusEnum.INACTIVE:
        return "badge-bg-danger";
      case SubscriptionStatusEnum.EXPIRED:
        return "badge-bg-danger";
      case SubscriptionStatusEnum.CANCELED:
        return "badge-bg-danger";
      case SubscriptionStatusEnum.PAYMENT_FAILED:
        return "badge-bg-danger";
    }
  };

  const onCancel = async () => {
    try {
      await actionCancelSubscription();
      await actionRefreshToken();
      window.location.reload();
    } catch {}
  };

  return (
    <div className="subscription-card">
      <div className="d-flex flex-wrap justify-content-center align-items-center mb-2">
        <i className="fa fa-cog text-primary fs-19" aria-hidden="true"></i>{" "}
        &nbsp; &nbsp;
        <div className="font-weight-600 fs-19">Plano {plan.title}</div>
        &nbsp; &nbsp;
        <BadgeComponent
          data={{
            name: String(SubscriptionStatusLabels(plan.status)).toUpperCase(),
            bgColor: getBgColor(plan.status),
          }}
        />
      </div>

      <div className="text-center">
        <p className="text-black-300 fs-14 font-weight-400">{plan.subtitle}</p>
      </div>
      <div className="text-end mx-2">
        <span className="text-primary">
          <span className="fs-22 font-weight-600">{plan.price}</span>/
          <span className="fs-15">
            {SubscriptionPlanFrequencyLabels(plan.billingCycle)}
          </span>
        </span>
      </div>

      <div className="flex items-center gap-2 text-sm text-muted-foreground">
        <i className="fa fa-clock text-black-300" aria-hidden="true" />{" "}
        <span className="text-black-500 font-weight-400">
          Válido até dia {dateFormatter(plan.endsAt, "dd/MM/yyyy")}
        </span>
      </div>

      <div className="d-flex justify-content-end">
        <Button
          className="mx-1"
          onClick={() => setIsOpenCancelModal(true)}
          disabled={isNewSubscription}
        >
          Cancelar
        </Button>
        {!isNewSubscription && (
          <Button
            color="info"
            className="text-white"
            onClick={() => {
              navigate("/painel/assinatura/gerenciar-cartao");
            }}
          >
            Gerenciar Cartão
          </Button>
        )}
        {isNewSubscription && (
          <Button
            color="primary"
            className="mx-1"
            onClick={() => navigate("/painel/assinatura/assinar-plano")}
          >
            Assinar Plano
          </Button>
        )}
      </div>

      <Modal
        isOpen={isOpenCancelModal}
        toggle={() => setIsOpenCancelModal(false)}
        size="md"
      >
        <ModalHeader toggle={() => setIsOpenCancelModal(false)}>
          Cancelar Assinatura
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <i className="fa fa-cog text-primary fa-2xl	" aria-hidden="true"></i>{" "}
            <br />
            <br />
            <span className="col-12">
              <h5>Deseja cancelar assinatura do plano?</h5>
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpenCancelModal(false)}>
            Não
          </Button>
          <Button color="danger" onClick={onCancel}>
            SIM
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
